import React from "react"

import Layout from '../components/layout';
import {Helmet} from "react-helmet";
import { Link } from 'gatsby';
import { Title, CompanyPlaceInfo } from '../components/Globals';


const CareerPage = () => {
  const ref = React.createRef();

  const handleClick = () =>
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  const [ExploreJobs] = React.useState([

    {
      id: 1,
      title: 'Students/interns',
      desc: 'Work and learn with the best offshore software development company through internships and major projects.',
    },
    {
      id: 2,
      title: 'Entry-level professionals',
      desc: 'If you are career-driven and love to engage in challenging solutions, then join a reliable salesforce consulting partner to become the professional of your dreams. We take the responsibility for a career that rises sky high.'
    },
    {
      id: 3,
      title: 'Experienced professionals',
      desc: 'Salesforce-based company in USA - Cymetrix Software aims at presenting out-of-the-box solutions. Our strategies combined with your intellect will result in fruitful outcomes desired by all parties involved. '
    },
  ]);

  const [AreaInterset] = React.useState([
    {
      id: 1,
      title: 'Salesforce Consulting',
      url:'/services/salesforce-consulting',
      desc: 'We are a salesforce certified consulting partner and expert salesforce implementation partners in USA and India. We cater to the needs of clients in India and abroad. We provide a 360° all-around salesforce consulting services support right from implementation to training.',
    },
    {
      id: 2,
      title: 'Digital Marketing',
      desc: 'We work as digital marketing partners for our clients worldwide. We help them establish themselves in the digital world and ace in their marketing strategies.'
    },
    {
      id: 3,
      title: 'Spend Analysis Consultant',
      url: '/services/spend-analysis',
      desc: 'Cymetrix Software also provides spend analysis consulting solutions. We aim at reducing procurement costs and enhancing efficiency at the same time.'
    },
  ]);
  const handleSubmit = (event) => {
    event.preventDefault();
    let headers = new Headers();
    headers.append('Authorization', 'Basic ' + btoa('api' + ":" + '5d2220e3b61e542f83de699a4ece81ad-4d640632-9b883e3e'));
    let formData = new FormData();
    formData.append('from', event.target.email.value);
    formData.append('to','sandip@cymetrixsoft.com');
    formData.append('subject', event.target.subject.value);
    formData.append('text', event.target.message.value);
    let myFile = document.getElementById('uploadCV').files[0];
    formData.append('attachment',myFile, event.target.uploadCV.value);
    fetch('https://api.mailgun.net/v3/sandbox0f7d801b76fb45bba72ceff5856c284d.mailgun.org/messages', {
      method: 'post',
      headers: headers,
      body: formData,
    })
    .then(function (data) {
      if(data.status === 200) {
        //alert('Message sent successfuly');
        window.location = 'https://www.cymetrixsoft.com/Thankyou/';
      }
    })
    .catch(function (error) {
      console.log('Request failed', error);
    });
  }
  
  return(
    <Layout>
      <Helmet>
        <title>Work With Us | Cymetrix Software</title>
        <meta name="description" content="Embark on a journey of professional growth and advancement through Cymetrix careers. Explore a wide array of tools and enriching experiences that pave the way for your development."/>
        <meta name="keywords" content="cymetrix,salesforce crm software implementation partners india,salesforce cpq partner india,salesforce.com crm partners india,salesforce partners in india,salesforce consultants india,salesforce marketing cloud partners india,salesforce partners in mumbai,salesforce partners in USA,salesforce implementation partners usa,SALESFORCE PARTNERS INDIA,salesforce consultants india, Cymetrix, cymetrixsoft, Cymetrixsoftware"/>
        <link rel="canonical" href="https://www.cymetrixsoft.com/career/" />
      </Helmet>
      <div className="career-background flex flex-col">
        <p className="block text-base xl:text-5xl mt-16 xl:px-48 xl:mt-20 md:mt-16 sm:mt-24 lg:text-4xl md:text-3xl sm:text-3xl sma:text-xl
        text-white text-uppercase text-center">
          Explore the Careers at Cymetrix Software
        </p>
        <p className="block text-base xl:text-xl mt-4 md:mt-6 lg:text-lg md:text-base sm:text-base sma:text-base
        text-white text-uppercase text-center">
          Unleash your potential and apply at Cymetrix for a career that transforms you into the best version of yourself. 
        </p>
        <button class="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 my-5 border border-blue-500 
        hover:border-transparent rounded mt-4 md:mt-10 "onClick={handleClick} >
          Apply
        </button>
      </div>

      <div className="max-w-6xl mx-auto justify-center my-3 md:my-12 ">
          <h1 className="text-3xl my-3 sma:mx-3">Come evolve with us</h1>
          <p className="text-lg sma:mx-3">Join our diverse group of innovative developers, working together as one to provide solutions to organizations' and industries' most challenging obstacles. Use high-edge softwares and strategies to remodel the way every task is done and help reinvent the way for your goals.</p>
          <div className="flex sma:mx-3 md:justify-start">
            <Link to="/about/company" className="text-hover my-3">Learn more about Cymetrix</Link>
          </div>
          <p className="text-lg my-3 sma:mx-3">As we navigate through these changing COVID-19 times, we salesforce certified consulting partners has successfully redesigned ourselves to help our clients and provide salesforce offshore support.</p>
      </div>

      <div className="job-background w-full flex flex-col items-center lg:mb-8">
          <div className="max-w-6xl mx-4 sma:mb-4">
              <Title title="Explore Cymetrix jobs" classValue={'text-white sma:text-xl text-4xl'} />
              <div className="flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-4 md:py-14">
                {
                  ExploreJobs.map(job => {
                      return <div className="mt-4 md:w-1/2 lg:w-1/3 sma:w-full" key={job.id}>
                          <div className="bg-white p-4 h-full border-b-4 border-pink-500 rounded-lg hover:border-hover flex flex-col items-center sm:mx-2 sm:p-3 md:p-8">
                              <h1 className="text-2xl mt-5 text-center">{job.title}</h1>
                              <p className="mt-5 text-sm text-gray-600 text-center">{job.desc}</p>
                          </div>
                      </div>
                  })
                }
              </div>

              <Title title="Areas of interest" classValue={'text-white sma:text-xl text-4xl'} />
              <div className="flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-4 md:py-14">
                {
                  AreaInterset.map(job => {
                      return <div className="mt-4 md:w-1/2 lg:w-1/3 sma:w-full" key={job.id}>
                          <div className="bg-white p-4 h-full border-b-4 border-pink-500 rounded-lg hover:border-hover flex flex-col items-center sm:mx-2 sm:p-3 md:p-8">
                          <h1 className="text-2xl mt-5 text-center"> <Link to={job.url} >{job.title}</Link></h1>
                              <p className="mt-5 text-sm text-gray-600 text-center">{job.desc}</p>
                          </div>
                      </div>
                  })
                }
              </div>
          </div>
        </div>
        <main className="w-full flex flex-col items-center" ref={ref}>
          <Title title="Get in touch with us" />
          <div className="flex justify-center text-center sma:text-xl sm:text-3xl sma:m-6">Fill in the form below to drop us an email</div>
          <form className="justify-center flex" onSubmit={handleSubmit} enctype="multipart/form-data">
            <div className="bg-white rounded px-8 pt-6 pb-8 mb-4 flex flex-col max-w-6xl sm:w-10/12">
              <div className="-mx-3 md:flex mb-6 md:space-x-2">
                <div className="md:w-1/2 mb-6 md:mb-0">
                  <label className="uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="name">
                    Enter Your Name*
                  </label>
                <input className="w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 mb-3" id="name" name="name"
                      type="text" placeholder="" />
              </div>
              <div className="md:w-1/2">
                <label className="uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="email">
                  Enter Your Email*
                </label>
                <input className="w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 mb-3" id="email" name="email"
                      type="email" placeholder="" />
              </div>
            </div>
            <div className="-mx-3 md:flex mb-6">
              <div className="md:w-full">
                <label className="uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="subject">
                  Enter Your Subject*
                </label>
                <input className="w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 mb-3"
                      id="subject" type="text" placeholder="" name="subject" />
              </div>
            </div>
            
            <div className="-mx-3 md:flex mb-6">
              <div className="md:w-full">
                <label className="uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="subject">
                  Upload your CV*
                </label>
                <input className="w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 mb-3" 
                      type="file" id="uploadCV" placeholder="" name="uploadCV" />
              </div>
            </div>

            <div className="-mx-3 md:flex mb-6">
              <div className="md:w-full">
                <label className="uppercase tracking-wide text-black text-xs font-bold mb-2" htmlFor="subject">
                  Enter Your Message*
                </label>
                <textarea className="w-full bg-gray-200 text-black border border-gray-200 rounded py-3 px-4 mb-3"
                          id="message" type="text" placeholder="" name="message" >

                </textarea>
              </div>
            </div>
            <div className="-mx-3 md:flex mt-2">
              <div className="sma:w-full">
                <button
                  className="sma:w-full bg-header text-white font-bold py-2 px-4 border-b-4 hover:border-b-#263f5f2 border-gray-500 hover:border-gray-100 uppercase rounded-full" type="submit">
                  Send Message
                </button>
              </div>
            </div>
          </div>
        </form>
      </main>
    </Layout>
  );
}

export default CareerPage;
